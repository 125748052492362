<template>
  <v-container fill-height>
    <v-snackbar v-model="snackbar" :color="snackbarColor">{{ snackbarMessage }}</v-snackbar>
    <v-row justify="center" align="center" class="mb-12">
      <v-col cols="12" md="6">
        <h1 class="display-4 text-center mb-10">
          Get in touch
        </h1>
        <p class="text-center">
          Use the below form to get in touch with us
        </p>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12">
              <text-field
                  outlined
                  dense
                  label="Your name"
                  v-model="form.name"
                  :rules="[rules.required]"
              ></text-field>
            </v-col>
            <v-col cols="12">
              <text-field
                  label="Your email address"
                  v-model="form.email"
                  :rules="[rules.required, rules.email]"
              ></text-field>
            </v-col>
            <v-col cols="12">
              <text-field
                  label="Your phone number"
                  v-model="form.phone"
                  :rules="[rules.required]"
              ></text-field>
            </v-col>
            <v-col cols="12">
              <text-area
                  label="Your message"
                  v-model="form.message"
                  :rules="[rules.required]"
              ></text-area>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <btn
                color="accent"
                class="no-transform"
                :loading="loading"
                :disabled="!valid"
                @click="submitForm"
            >Submit enquiry
            </btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import {createEnquiry} from '@/graphql'

export default {
  data: () => ({
    valid: null,
    form: {
      name: null,
      email: null,
      phone: null,
      message: null
    },
    loading: false,
    rules: {
      required: v => !!v || 'This field is required',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    },
    snackbar: false,
    snackbarColor: 'info',
    snackbarMessage: null
  }),
  filters: {
    toDay(value) {
      return moment().day(value + 1).format("dddd")
    },
    niceTime(value) {
      return value.slice(0, 5)
    }
  },
  computed: mapState(['store']),
  methods: {
    submitForm() {
      this.loading = true
      this.$apollo.mutate({
        mutation: createEnquiry,
        variables: this.form
      }).then((result) => {
        if (result) {
          this.loading = false
          this.snackbar = true
          this.snackbarColor = 'success'
          this.snackbarMessage = 'Message sent!'
        }
      }).catch((error) => {
        this.loading = false
        this.snackbar = true
        this.snackbarColor = 'error'
        this.snackbarMessage = error.message.replace('GraphQL error', '')
      })
    }
  }
}
</script>

<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>

